@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
}

.error-message, .error-message-plain {
  background: #c51244 !important;
  padding: 5px !important;
  border-radius: 0 !important;
  position: relative; 
  display: inline-block !important;
  box-shadow: 1px 1px 1px #aaaaaa;
  margin-top: 10px;
  color: #ffffff !important;
  font-size: 0.65rem;
}
.error-message:before{
  content: '';  
  width: 0;  
  height: 0;  
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #c51244;
  position: absolute;  
  top: -10px; 
}
.error-message-table {
  background: #c51244 !important;
  border-radius: 0 !important;
  position: relative; 
  display: table-cell !important;
  box-shadow: 1px 1px 1px #aaaaaa;
  margin-top: 10px;
  color: #ffffff !important;
  font-size: 0.65rem;
}

.label-on-input {
  @apply text-xs font-medium leading-6 text-gray-900 top-5 relative left-2 px-1 bg-white
}

.label-on-input-readonly {
  @apply text-xs font-medium leading-6 top-5 relative left-2 px-1 text-stone-50 bg-gray-800 rounded 
}

input:read-only {
  background-color: #e9ecef;
  color: #495057;
  cursor: not-allowed;
}

.process-th {
  @apply px-1 py-1 text-left text-sm font-semibold text-gray-900 sm:table-cell whitespace-nowrap
}

.process-results {
  @apply px-1 py-1 text-sm text-gray-500 sm:table-cell items-center justify-center
}

.tooltip-container {
  position: relative;
  cursor: pointer;
}

.tooltip-text {
  background-color: green;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  white-space: nowrap;

  max-width: 75vw; /* 50% of the screen width */
  min-width: 35vw; /* 50% of the screen width */
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words if necessary */
}

.tooltip-hover {
  background-color: green;
  @apply transition-opacity p-2 text-xs text-gray-100 rounded-md 
    absolute left-1/2 -translate-x-1/2 opacity-0 mt-5 m-auto text-center whitespace-pre-wrap 
    min-w-96;
}

.filter-field {
  @apply block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

input.filter-field {
  @apply py-1.5 px-4 
}